body {
  background-color: #3c0f4f;
}

.App {
  background: center / cover no-repeat url("../assets/background_eth.jpg");
}

.btn-primary {
  --btn-block-padding: calc(4 / var(--base-font-size) * 1rem);
  --btn-font-size: calc(15 / var(--base-font-size) * 1rem);
  background-color: #291940;
  font-size: var(--btn-font-size);
  line-height: calc(23 / var(--base-font-size) * 1rem);
  padding-block: var(--btn-block-padding);
  padding-inline: calc(16 / var(--base-font-size) * 1rem);
  padding: var(--btn-block-padding);
  font-family: canada-type-gibson, sans-serif;
  font-weight: 500;
  border-radius: 100px;
  cursor: pointer;
  appearance: none;
  color: white;
  margin: 0 auto;
}

.btn-primary:disabled > h4, .network-btns:disabled > h4 {
  opacity: 0.65;
  cursor: not-allowed;
}

.main-content .btn-primary {
  --btn-block-padding: calc(14 / var(--base-font-size) * 1rem);
  --btn-font-size: calc(18 / var(--base-font-size) * 1rem);
  width: calc(298 / var(--base-font-size) * 1rem);
  max-width: 100%;
}

.btn-secondary {
  --btn-block-padding: calc(14 / var(--base-font-size) * 1rem);
  --btn-font-size: calc(18 / var(--base-font-size) * 1rem);
  width: calc(298 / var(--base-font-size) * 1rem);
  max-width: 100%;
  font-size: var(--btn-font-size);
  line-height: calc(23 / var(--base-font-size) * 1rem);
  padding-block: var(--btn-block-padding);
  padding-inline: calc(16 / var(--base-font-size) * 1rem);
  padding: var(--btn-block-padding);
  font-family: canada-type-gibson, sans-serif;
  font-weight: 500;
  border-radius: 100px;
  cursor: pointer;
  appearance: none;
  color: white;
  background-color: var(--brand-rose);
}

.btn-slim {
  --btn-block-padding: calc(14 / var(--base-font-size) * 1rem);
  --btn-font-size: calc(18 / var(--base-font-size) * 1rem);
  width: calc(298 / var(--base-font-size) * 1rem);
  max-width: 100%;
  font-size: 14px;
  line-height: calc(23 / var(--base-font-size) * 0.5rem);
  padding-block: var(--btn-block-padding);
  padding-inline: calc(16 / var(--base-font-size) * 0.5rem);
  padding: var(--btn-block-padding);
  font-family: canada-type-gibson, sans-serif;
  font-weight: 4;
  border-radius: 100px;
  cursor: pointer;
  appearance: none;
  color: white;
  background-color: var(--brand-violet);
}

.btn-primary.with-icon {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 400;
}

.btn-primary[disabled],
.btn-primary:disabled {
  background-color: var(--brand-violet);
  cursor: not-allowed;
}

.main-content {
  width: calc(400 / var(--base-font-size) * 1rem);
  /* min-height: calc(387 / var(--base-font-size) * 1rem); */
  height: calc(375 / var(--base-font-size) * 1rem);
  max-width: 100%;
  border-radius: 15px;
  border: 4px solid var(--brand-rose);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.walletButtonContainer {
  width: fit-content;
  margin: 0 auto;
  height: 100%;
}

.walletButtonContainer img {
  max-height: 100px;
}

.walletButtonContainer button {
  transition: 0.3s filter;
}

.walletButtonContainer button img {
  transition: 0.3s transform;
}

.walletButtonContainer:hover button {
  filter: grayscale(1);
}

.walletButtonContainer:hover button:hover {
  filter: none;
}

.walletButtonContainer:hover button:hover img {
  /* transform: scale(1.1); */
}

.pill {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--brand-white);
  border: 2px solid var(--brand-white);
  color: var(--brand-white);
  font-family: canada-type-gibson, sans-serif;
  border-radius: 25px;
  overflow: hidden;
  user-select: none;
}

.pill p {
  background-color: var(--brand-violet);
  padding: 0.5em 1.25em 0.4em;
  border-radius: 25px;
  font-size: 1.1em;
}

.pill div {
  padding: 0 0.75em 0 0.5em;
  cursor: pointer;
}

input::placeholder {
  color: rgb(222, 222, 222);
  font-size: 0.825em;

}
input {
  border-radius: 25px;
  color: var(--brand-violet);
  background-color: #FFFFFF;
  padding: 4px 8px 4px 8px;
  font-size: 1.5em;
  font-weight: 700;
  width: 98%;
  margin: 4px auto 8px auto;
  border: 1px solid rgb(204, 204, 204);
  text-align: center;
}
input:focus {
  margin: 4px auto 8px auto;
  outline: none !important;
  border:1px solid rgb(204, 204, 204);
  box-shadow: rgba(149, 157, 165, 0.35) 0px 8px 24px;
}

.store-badge {
  width: fit-content;
  margin: 1em auto 0;
  display: block;
}

.store-badge img {
  height: 60px;
  width: auto;
}